import useLocationData from '@s/hooks/useLocationData';
import { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Form, Input, InputFlex, Label, Thanks } from './FormComponents';
import { PrimaryBtn } from './buttons';

export const ContactForm = () => {
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmit(true);

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    };

    const { pageTitle } = useLocationData();

    return (
        <Form onSubmit={handleSubmit} submit={submit} id="appointment">
            <h3>{pageTitle === 'contact-us' ? 'Contact Us' : 'Request an Appointment'}</h3>
            <InputFlex>
                <Label htmlFor="fName">
                    <span>First Name</span>
                    <Input
                        type="text"
                        id="fName"
                        name="fName"
                        placeholder="Enter owner’s first name"
                    />
                </Label>

                <Label htmlFor="lName">
                    <span>Last Name</span>
                    <Input
                        type="text"
                        id="lName"
                        name="lName"
                        placeholder="Enter owner’s last name"
                    />
                </Label>
            </InputFlex>

            <InputFlex>
                <Label htmlFor="email">
                    <span>
                        Email address<strong>*</strong>
                    </span>
                    <Input
                        type="email"
                        id="email"
                        name="email"
                        required
                        placeholder="Enter your email address"
                    />
                </Label>
                <Label htmlFor="phone">
                    <span>Phone *</span>
                    <PatternFormat
                        type="tel"
                        format="+1 (###) ###-####"
                        customInput={Input}
                        placeholder="123 456-7890"
                        id="phone"
                        name="phone"
                        required
                    />
                </Label>
            </InputFlex>

            <Label htmlFor="message">
                <span>Your message</span>
                <Input
                    as="textarea"
                    id="message"
                    name="message"
                    rows={6}
                    placeholder="Write to us..."
                />
            </Label>
            <PrimaryBtn>
                {pageTitle === 'contact-us' ? 'Send Us a Message' : 'Request an Appointment'}
            </PrimaryBtn>
            <Thanks submit={submit} />
        </Form>
    );
};
