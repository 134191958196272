import { CLink } from '@/CLink';
import { ContactForm } from '@/ContactForm';
import { siteInfo } from '@/layout/NavLinks';
import { ReactComponent as AddressIcon } from '@a/icons/address.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import styled from '@emotion/styled';
import { leftLine } from './styles/Typography';
import { flexCenter, flexSpace, flexStart, px, py } from './styles/classes';

const StyledContact = styled.section`
    ${px};
    ${py};
    max-width: 1312px;
    box-sizing: content-box;
    margin: 0 auto;

    > div {
        margin-bottom: 48px;
        flex-shrink: 0;

        > h2 {
            ${leftLine};
            margin: 0;
        }
    }

    @media (min-width: 1280px) {
        ${flexSpace};

        > div {
            flex-grow: 1;
            max-width: 600px;
            margin-right: 32px;
            margin-bottom: 0;
        }
    }
`;

const Flex = styled.div`
    margin: 32px 0;
    max-width: 600px;

    @media (min-width: 768px) {
        ${flexSpace};
        align-items: flex-start;
    }
`;

const Info = styled.div`
    margin-bottom: 24px;
    max-width: 320px;

    > a {
        ${flexStart};
        align-items: center;
        max-width: max-content;
        transition: color 0.3s ease-in-out;

        :not(:last-of-type) {
            margin: 0 0 24px;
        }

        > span {
            ${flexCenter};
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            background-color: ${({ theme }) => theme.colors.primary};
            border-radius: 10px;
            margin-right: 16px;
            transition: background-color 0.3s ease-in-out;

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.white};
                }
            }
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.secondary};

            > span {
                background-color: ${({ theme }) => theme.colors.secondary};
            }
        }
    }

    @media (min-width: 768px) {
        margin-bottom: 0;
        margin-right: 32px;
    }
`;

const Hours = styled.div`
    max-width: 520px;

    > p {
        ${flexSpace};
        margin: 0 0 16px;

        > span:first-of-type {
            font-weight: 500;
        }

        :last-of-type {
            margin-bottom: 0;
            color: ${({ theme }) => theme.colors.gray};
        }
    }

    @media (min-width: 768px) {
        margin-bottom: 0;
        width: 252px;
    }
`;

const Iframe = styled.iframe`
    border: 0;
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
    min-height: 200px;
    aspect-ratio: 342 / 220;
    border-radius: 15px;
    max-width: 600px;

    @media (min-width: 1150px) {
        height: 340px;
        aspect-ratio: unset;
    }
`;

export const ContactSection = () => {
    return (
        <StyledContact>
            <div>
                <h2>Contact / Hours</h2>
                <Flex>
                    <Info>
                        <CLink to={siteInfo.address.link}>
                            <span>
                                {' '}
                                <AddressIcon />
                            </span>
                            {siteInfo.address.text}
                        </CLink>
                        <CLink to={siteInfo.phone.link}>
                            <span>
                                {' '}
                                <PhoneIcon />{' '}
                            </span>
                            {siteInfo.phone.text}
                        </CLink>
                    </Info>

                    <Hours>
                        <p>
                            <span>Monday</span>
                            <span>8 am - 5 pm</span>
                        </p>
                        <p>
                            <span>Tuesday</span>
                            <span>7 am - 4 pm</span>
                        </p>
                        <p>
                            <span>Wednesday</span>
                            <span>8 am - 5 pm</span>
                        </p>
                        <p>
                            <span>Thursday</span>
                            <span>7 am - 12 pm</span>
                        </p>
                        <p>
                            <span>Friday</span>
                            <span>Closed</span>
                        </p>
                    </Hours>
                </Flex>

                <Iframe
                    title="morris family dentistry location on map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3075.2138674648713!2d-104.9891942!3d39.5773301!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c81800d4e4585%3A0xc07aca834fce5e63!2sMorris%20Family%20Dentistry!5e0!3m2!1sen!2s!4v1681823174804!5m2!1sen!2s"
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></Iframe>
            </div>
            <ContactForm />
        </StyledContact>
    );
};
