import { flexCenter } from '@/layout/styles/classes';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const plainBtnStyles = css`
    ${flexCenter};
    display: inline-flex;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    line-height: 150%;
    cursor: pointer;
    font-weight: 500;
    font-size: 1rem;

    @media (min-width: 1280px) {
        font-size: 1.125rem;
    }
`;

export default styled.button<{ to?: string; iconRight?: boolean; iconLeft?: boolean }>`
    ${plainBtnStyles};

    > svg {
        margin-right: ${({ iconLeft }) => (iconLeft ? '16px' : 0)};
        margin-left: ${({ iconRight }) => (iconRight ? '16px' : 0)};
    }
`;
