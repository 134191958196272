import styled from '@emotion/styled';
import PlainBtn from './Plain';

const Btn = styled(PlainBtn)<{ white?: boolean }>`
    border-radius: 50px;
    border: 1.5px solid ${({ theme, white }) => (white ? theme.colors.light : theme.colors.black)};
    transition: border-color 0.3s ease-in-out;
    position: relative;
    isolation: isolate;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -1.5px;
        border-radius: inherit;
        background-image: ${({ theme }) => theme.colors.gradient};
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    > span {
        color: ${({ theme, white }) => (white ? theme.colors.light : theme.colors.black)};
        transition: color 0.3s ease-in-out;
        padding: 16px 24px;
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: inherit;
    }

    :hover {
        border-color: transparent;

        > span {
            color: ${({ theme }) => theme.colors.primary};
        }

        ::before {
            opacity: 1;
        }
    }

    :focus-visible {
        border-color: transparent;

        > span {
            color: ${({ theme }) => theme.colors.primary};
        }

        ::before {
            opacity: 1;
        }
    }
`;

type BtnProps = React.HTMLAttributes<HTMLButtonElement> & {
    as?: React.ElementType;
    children: React.ReactNode;
    to?: string;
    white?: boolean;
};

export default function SecondaryBtn({ children, ...props }: BtnProps) {
    return (
        <Btn {...props}>
            <span>{children}</span>
        </Btn>
    );
}
