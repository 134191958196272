import { plainBtnStyles } from '@/buttons/Plain';
import { css } from '@emotion/react';
import { theme } from './styles/GlobalStyles';
import { flexCenter } from './styles/classes';

const dynamicStyles = css`
    &[aria-current='page'] {
        color: ${theme.colors.primary};
    }

    &.current-nav-category {
        color: ${theme.colors.primary};

        > svg path {
            fill: ${theme.colors.primary};
        }
    }

    &[data-mobile-expanded],
    &[aria-expanded='true'] {
        > svg {
            transform: rotate(180deg);
        }
    }

    :hover {
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.black};

        > svg path {
            fill: ${theme.colors.black};
        }
    }

    :focus-visible {
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.black};

        > svg path {
            fill: ${theme.colors.black};
        }
    }
`;

export const navLinkStyles = css`
    ${dynamicStyles};
    ${flexCenter};
    display: inline-flex;
    text-transform: capitalize;
    color: ${theme.colors.black};
    transition: background-color 0.3s ease-in-out;
    margin-bottom: 0;
    padding: 16px;
    font-weight: 500;
    line-height: 150%;
    font-size: 1rem !important;
    margin-bottom: 8px;

    @media (min-width: 1024px) {
        margin-bottom: 0 !important;
        background-color: ${theme.colors.light};
    }

    @media (min-width: 1440px) {
        padding: 24px;
    }
`;

export const subNavLinkStyles = css`
    ${navLinkStyles};
    display: flex;
    padding: 8px 16px !important;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    @media (min-width: 1440px) {
        padding: 12px 20px !important;
    }

    &[aria-current='page'] {
        color: ${theme.colors.secondary};
    }

    :hover {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.light};
    }

    :focus-visible {
        background-color: ${theme.colors.primary};
        color: ${theme.colors.light};
    }
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};

    > svg {
        margin-left: 16px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;
        transform: rotate(-90deg);

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${theme.colors.black};
        }
    }

    @media (min-width: 1024px) {
        > svg {
            transform: rotate(0);
        }
    }
`;
