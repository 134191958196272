exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-areas-served-arapahoe-county-mdx": () => import("./../../../src/pages/areas-served/arapahoe-county.mdx" /* webpackChunkName: "component---src-pages-areas-served-arapahoe-county-mdx" */),
  "component---src-pages-areas-served-centennial-mdx": () => import("./../../../src/pages/areas-served/centennial.mdx" /* webpackChunkName: "component---src-pages-areas-served-centennial-mdx" */),
  "component---src-pages-areas-served-douglas-county-mdx": () => import("./../../../src/pages/areas-served/douglas-county.mdx" /* webpackChunkName: "component---src-pages-areas-served-douglas-county-mdx" */),
  "component---src-pages-areas-served-greenwood-village-mdx": () => import("./../../../src/pages/areas-served/greenwood-village.mdx" /* webpackChunkName: "component---src-pages-areas-served-greenwood-village-mdx" */),
  "component---src-pages-areas-served-index-tsx": () => import("./../../../src/pages/areas-served/index.tsx" /* webpackChunkName: "component---src-pages-areas-served-index-tsx" */),
  "component---src-pages-areas-served-meridian-mdx": () => import("./../../../src/pages/areas-served/meridian.mdx" /* webpackChunkName: "component---src-pages-areas-served-meridian-mdx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-referring-doctors-tsx": () => import("./../../../src/pages/referring-doctors.tsx" /* webpackChunkName: "component---src-pages-referring-doctors-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-amalgam-filling-replacement-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/amalgam-filling-replacement/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-amalgam-filling-replacement-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-antibiotics-dentistry-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/antibiotics-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-antibiotics-dentistry-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-bone-loss-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/bone-loss/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-bone-loss-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-can-oil-pulling-reverse-cavities-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/can-oil-pulling-reverse-cavities/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-can-oil-pulling-reverse-cavities-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-complications-of-wisdom-teeth-extraction-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/complications-of-wisdom-teeth-extraction/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-complications-of-wisdom-teeth-extraction-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-dentistry-for-a-lifetime-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/dentistry-for-a-lifetime/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-dentistry-for-a-lifetime-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-favorite-dental-stories-1-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/favorite-dental-stories-1/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-favorite-dental-stories-1-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-favorite-dental-stories-2-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/favorite-dental-stories-2/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-favorite-dental-stories-2-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-favorite-dental-stories-3-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/favorite-dental-stories-3/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-favorite-dental-stories-3-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-favorite-dental-stories-4-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/favorite-dental-stories-4/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-favorite-dental-stories-4-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-favorite-dental-stories-5-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/favorite-dental-stories-5/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-favorite-dental-stories-5-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-honest-dentistry-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/honest-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-honest-dentistry-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-kids-and-dentistry-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/kids-and-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-kids-and-dentistry-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-modern-dentistry-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/modern-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-modern-dentistry-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-my-tooth-was-knocked-out-during-surgery-intubation-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/my-tooth-was-knocked-out-during-surgery-intubation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-my-tooth-was-knocked-out-during-surgery-intubation-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-never-get-a-cavity-again-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/never-get-a-cavity-again/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-never-get-a-cavity-again-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-platelet-rich-fibrin-prf-to-prevent-dry-sockets-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/platelet-rich-fibrin-prf-to-prevent-dry-sockets/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-platelet-rich-fibrin-prf-to-prevent-dry-sockets-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-relief-from-dry-socket-pain-after-wisdom-teeth-removal-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/relief-from-dry-socket-pain-after-wisdom-teeth-removal/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-relief-from-dry-socket-pain-after-wisdom-teeth-removal-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-the-perfectly-clean-mouth-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/the-perfectly-clean-mouth/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-the-perfectly-clean-mouth-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-tips-for-recovery-after-wisdom-tooth-removal-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/tips-for-recovery-after-wisdom-tooth-removal/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-tips-for-recovery-after-wisdom-tooth-removal-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-why-is-dentistry-so-expensive-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/why-is-dentistry-so-expensive/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-why-is-dentistry-so-expensive-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-wisdom-teeth-recovery-healing-time-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/blog/wisdom-teeth-recovery-healing-time/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-wisdom-teeth-recovery-healing-time-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-bridges-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/dental-bridges/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-bridges-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-crowns-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/dental-crowns/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-crowns-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dental-implants-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/dental-implants/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dental-implants-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-dentures-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/dentures/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-dentures-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-family-dentistry-cosmetic-dentistry-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/family-dentistry/cosmetic-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-family-dentistry-cosmetic-dentistry-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-family-dentistry-general-dentistry-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/family-dentistry/general-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-family-dentistry-general-dentistry-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-family-dentistry-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/family-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-family-dentistry-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-root-canal-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/root-canal/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-root-canal-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-sedation-dentistry-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/sedation-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-sedation-dentistry-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-veneers-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/veneers/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-veneers-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-wisdom-teeth-removal-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/wisdom-teeth-removal/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-wisdom-teeth-removal-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-wisdom-teeth-removal-recovery-from-wisdom-teeth-removal-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/wisdom-teeth-removal/recovery-from-wisdom-teeth-removal/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-wisdom-teeth-removal-recovery-from-wisdom-teeth-removal-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-wisdom-teeth-removal-specials-on-wisdom-teeth-extractions-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/wisdom-teeth-removal/specials-on-wisdom-teeth-extractions/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-wisdom-teeth-removal-specials-on-wisdom-teeth-extractions-index-mdx" */),
  "component---src-templates-service-tsx-content-file-path-src-content-services-wisdom-teeth-removal-wisdom-teeth-facts-index-mdx": () => import("./../../../src/templates/service.tsx?__contentFilePath=/home/runner/work/morris-dentistry/morris-dentistry/src/content/services/wisdom-teeth-removal/wisdom-teeth-facts/index.mdx" /* webpackChunkName: "component---src-templates-service-tsx-content-file-path-src-content-services-wisdom-teeth-removal-wisdom-teeth-facts-index-mdx" */)
}

