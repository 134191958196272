import { CLink } from '@/CLink';
import { PrimaryBtn } from '@/buttons';
import { ReactComponent as FacebookIcon } from '@a/icons/facebook.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as TwitterIcon } from '@a/icons/twitter.svg';
import { ReactComponent as Logo } from '@a/images/layout/logo.svg';
import styled from '@emotion/styled';
import { footerNavLinks, siteInfo } from './NavLinks';
import { smTxt } from './styles/Typography';
import { flexCenter, flexSpace, flexStart, px, py } from './styles/classes';

const StyledFooter = styled.section`
    ${px};
    ${py};
    border-top: 16px solid ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.black};
    padding-top: 64px;
    padding-bottom: 32px;
    color: ${({ theme }) => theme.colors.white};

    @media (min-width: 1024px) {
        > div {
            ${flexSpace};
            align-items: flex-start;
            margin: 0 auto;
            max-width: 1312px;
        }
    }
`;

const Intro = styled.div`
    max-width: 360px;
    margin-bottom: 48px;

    > a {
        :first-of-type {
            svg {
                path {
                    transition: fill 0.3s ease-in-out;

                    :last-of-type {
                        fill: ${({ theme }) => theme.colors.primary};
                    }
                }
            }

            :hover,
            :focus {
                svg path:last-of-type {
                    fill: #39667b;
                }
            }
        }

        :last-of-type {
            display: flex;
            max-width: max-content;
            margin: 16px 0 32px;
        }
    }

    > h1 {
        font-size: 1.25rem;
        line-height: 175.18%;
        font-weight: 600;
        margin: -7px 0 0;
    }

    @media (min-width: 1024px) {
        margin-right: auto;
        margin-bottom: 0;

        > a:last-of-type {
            margin: 32px 0 64px;
        }
    }
`;

const Social = styled.div`
    ${flexStart};
    align-items: center;

    > p {
        margin: 0 32px 0 0;
    }

    > a {
        display: flex;

        :not(:last-of-type) {
            margin-right: 32px;
        }

        > svg path {
            transition: fill 0.3s ease-in-out;
        }

        :hover,
        :focus {
            > svg path {
                fill: ${({ theme }) => theme.colors.primary};
            }
        }
    }
`;

const FooterLinks = styled.div`
    display: flex;
    flex-direction: column;

    :last-of-type {
        margin-top: 48px;
    }

    > h5 {
        font-weight: 600;
        margin: 0 0 16px;
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 175.18%;
    }

    > a {
        ${smTxt};
        margin-bottom: 12px;
        color: ${({ theme }) => theme.colors.white};
        transition: color 0.3s ease-in-out;
        text-transform: capitalize;
        max-width: max-content;

        &:focus,
        &:hover {
            color: ${({ theme }) => theme.colors.primary};
        }

        :last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        :last-of-type {
            margin-top: 0;
            margin-left: ${({ theme }) => theme.spacing.xxl};
        }
    }
`;

const Copyright = styled.p`
    ${flexCenter};
    color: ${({ theme }) => theme.colors.gray};
    margin-top: 64px;
    margin-bottom: 0;
`;

export const Footer = () => {
    return (
        <StyledFooter>
            <div>
                <Intro>
                    <CLink to="/">
                        <Logo aria-label="morris family dentistry" />
                    </CLink>

                    <h1>Dr Christopher R. Morris</h1>
                    <PrimaryBtn iconRight as={CLink} to={siteInfo.phone.link}>
                        Call Us at {siteInfo.phone.text}
                        <PhoneIcon />
                    </PrimaryBtn>
                    <Social>
                        <p>Follow us at:</p>
                        <CLink to={siteInfo.social.facebook}>
                            <FacebookIcon aria-label="morris family dentistry facebook page" />
                        </CLink>
                        <CLink to={siteInfo.social.twitter}>
                            <TwitterIcon aria-label="morris family dentistry twitter page" />
                        </CLink>
                    </Social>
                </Intro>
                <FooterLinks>
                    <h5>navigate</h5>
                    {footerNavLinks.slice(0, 5).map((link, i) => (
                        <CLink to={link.link} key={i}>
                            {link.text}
                        </CLink>
                    ))}
                </FooterLinks>
                <FooterLinks>
                    <h5>services</h5>
                    {footerNavLinks.slice(5).map((link, i) => (
                        <CLink to={link.link} key={i}>
                            {link.text}
                        </CLink>
                    ))}
                </FooterLinks>
            </div>
            <Copyright>
                Dr Christopher Morris | All Rights Reserved | {new Date().getFullYear()}
            </Copyright>
        </StyledFooter>
    );
};
