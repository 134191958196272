import { CLink } from '@/CLink';
import { PrimaryBtn } from '@/buttons';
import { ReactComponent as Logo } from '@a/images/layout/logo.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import { navigate } from 'gatsby';
import { Fragment, useState } from 'react';
import { DesktopNav, NavItem } from './DesktopNavPrimitive';
import { MobNav } from './MobNav';
import { navLinks } from './NavLinks';
import { flexSpace, px } from './styles/classes';

const Navbar = styled.header`
    ${flexSpace};
    ${px};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.light};
    margin-right: var(--removed-body-scroll-bar-size);
    z-index: 100;
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};

    @media (min-width: 1921px) {
        padding: 16px calc((100vw - 1760px) / 2);
    }
`;

const HomeLink = styled(CLink)`
    display: flex;
    flex-shrink: 0;

    > svg path {
        transition: fill 0.3s ease-in-out;
    }

    :hover {
        path {
            :first-of-type {
                fill: ${({ theme }) => theme.colors.primary};
            }

            :last-of-type {
                fill: ${({ theme }) => theme.colors.secondary};
            }
        }
    }

    :focus-visible {
        path {
            :first-of-type {
                fill: ${({ theme }) => theme.colors.primary};
            }

            :last-of-type {
                fill: ${({ theme }) => theme.colors.secondary};
            }
        }
    }

    @media (max-width: 1149px) {
        > svg {
            width: 120px;
            height: 48px;
        }
    }
`;

const DesktopNavMenu = styled(DesktopNav)`
    width: 100%;
    display: none;

    @media (min-width: 1024px) {
        display: block;
    }
`;

const menuStyles = css`
    > li {
        position: relative; // for submenu align with item

        :first-of-type {
            margin-left: auto;
        }

        :last-of-type {
            margin-left: auto;
        }
    }
`;

const contentStyles = css`
    box-shadow: 0px 32px 64px -16px rgba(40, 84, 104, 0.5);
    border-radius: 16px;
    overflow: hidden;
    position: absolute;
    top: calc((1rem * 1.6968) + 32px);
    width: max-content;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);

    > ul {
        max-height: calc(100vh - 175px);
        overflow-y: auto;
    }

    @media (min-width: 1440px) {
        top: calc((1rem * 1.6968) + 48px);
    }
`;

export const scrollToAppointmentForm = () => {
    const element = document.querySelector('#appointment');
    const y = element && element.getBoundingClientRect().top + window.scrollY - 112;
    window.scroll({
        behavior: 'smooth',
        top: y || 0,
    });
};

export const Nav = () => {
    const [closeDialogs, setCloseDialogs] = useState(false);
    const closeAllDialogs = () => setCloseDialogs(open => !open);
    const { title } = useLocationData();

    return (
        <Fragment>
            <Navbar>
                <HomeLink to="/">
                    <Logo aria-label="morris family dentistry" />
                </HomeLink>

                <DesktopNavMenu
                    menuStyles={menuStyles}
                    contentStyles={contentStyles}
                    links={navLinks.slice(0, -1)}
                >
                    <NavItem>
                        <PrimaryBtn
                            onClick={() =>
                                title === 'contact us'
                                    ? navigate('/', {
                                          state: {
                                              scrollToForm: true,
                                          },
                                      })
                                    : scrollToAppointmentForm()
                            }
                            css={css`
                                @media (max-width: 1149px) {
                                    display: none;
                                }
                            `}
                        >
                            Request Appointment
                        </PrimaryBtn>
                    </NavItem>
                </DesktopNavMenu>

                <MobNav allDialogsClosed={closeDialogs} closeAllDialogs={closeAllDialogs} />
            </Navbar>
        </Fragment>
    );
};
