import styled from '@emotion/styled';
import PlainBtn from './Plain';

export default styled(PlainBtn)`
    color: ${({ theme }) => theme.colors.black};
    padding: 16px 24px;
    border-radius: 50px;
    border: 1.5px solid transparent;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
        box-shadow 0.3s ease-in-out;
    position: relative;
    background-clip: padding-box;
    background-color: ${({ theme }) => theme.colors.secondary};

    ::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -1.5px;
        border-radius: inherit;
        background-image: ${({ theme }) => theme.colors.gradient};
    }

    path {
        transition: fill 0.3s ease-in-out;
    }

    :hover {
        box-shadow: 0px 16px 16px -12px #2b87b1;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.primary};

        path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }

    :focus-visible {
        box-shadow: 0px 16px 16px -12px #2b87b1;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.primary};

        path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }
`;
