export const siteInfo = {
    phone: {
        text: '(303) 795 1443',
        link: 'tel:+13037951443',
    },
    email: {
        link: 'office@drchristophermorris.com',
        text: 'mailto:office@drchristophermorris.com',
    },
    address: {
        text: '7720 S Broadway Suite 430, Littleton, CO 80122',
        link: 'https://goo.gl/maps/sDgvVpjExWLTe3jV6',
    },
    social: {
        facebook: 'https://www.facebook.com/DrChristopherMorris/',
        twitter: 'https://twitter.com/toothsleuth1',
        google: 'https://www.google.com/search?q=morris+family+dentistry+littleton#lrd=0x876c81800d4e4585:0xc07aca834fce5e63,1,,,,',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'wisdom teeth removal',
        link: '',
        links: [
            {
                text: 'wisdom teeth removal',
                link: '/wisdom-teeth-removal/',
            },
            {
                text: 'specials on wisdom teeth extractions',
                link: '/wisdom-teeth-removal/specials-on-wisdom-teeth-extractions/',
            },
            {
                text: 'recovery from wisdom teeth removal',
                link: '/wisdom-teeth-removal/recovery-from-wisdom-teeth-removal/',
            },
            {
                text: 'wisdom teeth facts',
                link: '/wisdom-teeth-removal/wisdom-teeth-facts/',
            },
        ],
    },
    {
        text: 'sedation dentistry',
        link: '/services/sedation-dentistry/',
    },
    {
        text: 'services',
        link: '',
        links: [
            {
                text: 'family dentistry',
                link: '/family-dentistry/',
            },
            {
                text: 'cosmetic dentistry',
                link: '/family-dentistry/cosmetic-dentistry/',
            },
            {
                text: 'general dentistry',
                link: '/family-dentistry/general-dentistry/',
            },
            {
                text: 'dental implants',
                link: '/services/dental-implants/',
            },
            {
                text: 'root canal',
                link: '/services/root-canal/',
            },
            {
                text: 'dental bridges',
                link: '/services/dental-bridges/',
            },
            {
                text: 'dental crowns',
                link: '/services/dental-crowns/',
            },
            {
                text: 'veneers',
                link: '/services/veneers/',
            },
            {
                text: 'dentures',
                link: '/services/dentures/',
            },
        ],
    },
    {
        text: 'about us',
        link: '',
        links: [
            {
                text: 'about us',
                link: '/about-us/',
            },
            {
                text: 'contact us',
                link: '/contact-us/',
            },
            {
                text: 'referring doctors',
                link: '/referring-doctors/',
            },
            {
                text: 'blog',
                link: '/blog/',
            },
            {
                text: 'areas served',
                link: '/areas-served/',
            },
        ],
    },
    {
        text: 'request appointment',
        link: '#appointment',
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                // removed for this site
                // flat.push({
                //     text: data.text,
                //     link: data.link,
                // });
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: getNavLink('about us'),
    },
    {
        text: 'contact us',
        link: getNavLink('contact us'),
    },
    {
        text: 'blog',
        link: getNavLink('blog'),
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
    {
        text: 'sitemap',
        link: 'sitemap-pages.xml',
    },
    {
        text: 'wisdom teeth removal',
        link: getNavLink('wisdom teeth removal'),
    },
    {
        text: 'sedation dentistry',
        link: getNavLink('sedation dentistry'),
    },
    {
        text: 'dental implants',
        link: getNavLink('dental implants'),
    },
    {
        text: 'cosmetic dentistry',
        link: getNavLink('cosmetic dentistry'),
    },
    {
        text: 'family dentistry',
        link: getNavLink('family dentistry'),
    },
];

export function getNavLink(text: string) {
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === text.toLowerCase());

    if (linkObj) return linkObj.link;
    else return '';
}
